<template>
  <div>
    <img class="bg-img" src="@/assets/questionnaire-result-bg.png" alt="" />
    <div class="main">
      <div
        class="item"
        :class="[index === 0 ? '' : 'item-primary']"
        v-for="(item, index) in list"
        :key="index"
      >
        <div class="item-header">
          <div class="header-top">
            <span class="header-title">{{ item.productName }}</span>
            <span class="header-price"
              >预估价格<span class="header-price-num"
                >￥{{ item.salePrice }}元</span
              ></span
            >
          </div>
          <div class="header-desc">
            {{
              index === 0
                ? "根据健康评估，重点设计的体检方案"
                : "除推荐套餐外，还为您设计了其它健康套餐"
            }}
          </div>
        </div>

        <div class="item-body">
          <div>
            <div class="item-body-title">基础项目</div>
            <div class="item-body-desc">
              {{ item.description }}
            </div>
            <transition-group
              name="van-fade"
              tag="div"
              style="position: relative"
            >
              <div
                class="project-name"
                v-show="item.showAllProjects || index < 3"
                v-for="(project, index) in item.projects"
                :key="project"
              >
                {{ project }}>
              </div>
            </transition-group>
            <div
              v-if="!item.showAllProjects"
              class="look-more"
              @click="showProjects(index)"
            >
              查看更多
            </div>
          </div>

          <div>
            <div class="item-body-title">推荐项目</div>

            <transition-group
              name="van-fade"
              tag="div"
              style="position: relative"
            >
              <div
                class="project-name"
                v-show="item.showAllPackages || index < 3"
                v-for="(packages, index) in item.hospitalPackages"
                :key="packages.additionPackageId"
              >
                <div>{{ packages.additionPackageName }}></div>
                <div class="project-desc">{{ packages.describe }}</div>
              </div>
            </transition-group>

            <div
              v-if="!item.showAllPackages"
              class="look-more"
              @click="showPackages(index)"
            >
              查看更多
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      list: [],
    };
  },
  computed: {},
  mounted() {
    this.$setTitle("体检方案");
    document.querySelector("body").style.backgroundColor = "#F4F4F4";
    this.initData();
  },
  methods: {
    initData() {
      if (!localStorage.getItem("questionnaireBusinessResult")) return;
      this.list = JSON.parse(
        localStorage.getItem("questionnaireBusinessResult")
      );
      this.list.forEach((item) => {
        if (item.projects.length > 3) {
          item.showAllProjects = false;
        } else {
          item.showAllProjects = true;
        }
        if (item.hospitalPackages.length > 3) {
          item.showAllPackages = false;
        } else {
          item.showAllPackages = true;
        }
      });
    },
    showProjects(index) {
      this.list[index].showAllProjects = true;
      this.list.splice(index, 1, this.list[index]);
    },
    showPackages(index) {
      this.list[index].showAllPackages = true;
      this.list.splice(index, 1, this.list[index]);
    },
  },
};
</script>

<style>
html body {
  background-color: #f4f4f4 !important;
}
</style>

<style scoped>
.bg-img {
  width: 100%;
}
.main {
  position: relative;
  margin-top: -100px;
  padding: 0 10px;
}

.item {
  margin-bottom: 10px;
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
}

.item-header {
  position: relative;
  padding: 15px;
}

.item-header::after {
  content: "";
  width: 100%;
  height: 1px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #edeff0;
  transform: scaleY(0.5);
}

.header-top {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-title {
  font-size: 18px;
  font-weight: bold;
  color: #ff8718;
}

.item-primary .header-title {
  color: #00c4b3;
}

.header-top::before {
  content: "";
  width: 8px;
  height: 14px;
  position: absolute;
  top: 50%;
  left: -19px;
  border-radius: 7px;
  background-color: #ff8718;
  transform: translateY(-50%);
}

.item-primary .header-top::before {
  background-color: #00c4b3;
}

.header-price {
  font-size: 14px;
  color: #cccccc;
  font-weight: bold;
}

.header-price-num {
  margin-left: 5px;
  font-size: 14px;
  font-weight: bold;
  color: #ff3d00;
}

.header-desc {
  margin-top: 5px;
  font-size: 14px;
  color: #878d99;
}

.item-body {
  padding: 0 15px 15px 15px;
}

.item-body-title {
  position: relative;
  padding-left: 10px;
  margin-top: 15px;
  font-size: 16px;
  font-weight: bold;
  color: #2c2f37;
}

.item-body-title::before {
  content: "";
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #ff8718;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.item-primary .item-body-title::before {
  background-color: #00c4b3;
}

.item-body-desc {
  margin-top: 5px;
  font-size: 14px;
  color: #878d99;
}

.project-name {
  margin-top: 10px;
  font-size: 14px;
  font-weight: 400;
  color: #2c2f37;
}

.main-project:first-child {
  margin-top: 15px;
}

.look-more {
  text-align: center;
  margin-top: 15px;
  font-size: 14px;
  color: #00c4b3;
}

.project-desc {
  margin-top: 5px;
  font-size: 14px;
  color: #878d99;
}
</style>